import React, { Component } from 'react'
import Link from '../utils/link'

import LogoBlue from '../assets/images/logo-blue.svg'
import Phone from '../assets/images/phone.svg'
import { Instagram, Facebook } from './icons'

class Footer extends Component {

  state = {
    colActive: [],
  }

  _toggleCol = (itemId) => {
    let current = this.state.colActive[itemId]
    let { colActive } = this.state
    colActive[itemId] = !current
    this.setState({ colActive })
  }

  render() {

    let { colActive } = this.state

    return (
      <>
        <footer className='footer'>
          <div className='footer__inner'>
            <div className='footer__col'>
              <img className='footer__logo' src={LogoBlue} alt='Simply Orth' />
              <ul>
                <li>
                  <Link to='https://www.instagram.com/simplyorthodontics.australia/' target='_blank' rel='noopener noreferrer'>
                    <Instagram color={'#006084'} />
                  </Link>
                </li>
                <li>
                  <Link to='https://www.facebook.com/simplyorthodontics/' target='_blank' rel='noopener noreferrer'>
                    <Facebook color={'#006084'} />
                  </Link>
                </li>
              </ul>
              <p>© {new Date().getFullYear()} Simply Orthodontics<br />
              Design by <Link to='https://atollon.com.au' target='_blank' rel='noopener noreferrer'>Atollon</Link></p>
            </div>
            <div className='footer__col'>
              <h4 className={colActive[0] ? 'active' : ''} onClick={() => this._toggleCol(0)}>Sydenham</h4>
              <div className={colActive[0] ? 'footer__col-wrap active' : 'footer__col-wrap'}>
                <p>15-17 Overton Lea Boulevard<br />
                Sydenham VIC 3037</p>
                <p><Link to='tel:+61393908742'>(03) 9390 8742</Link><br />
                <Link to='mailto:info@simplyortho.com.au'>info@simplyortho.com.au</Link></p>
              </div>
            </div>
            <div className='footer__col'>
              <h4 className={colActive[2] ? 'active' : ''} onClick={() => this._toggleCol(2)}>Sunbury</h4>
              <div className={colActive[2] ? 'footer__col-wrap active' : 'footer__col-wrap'}>
                <p>31 Brook St<br />
                Sunbury VIC 3429</p>
                <p><Link to='tel:+61393908742'>(03) 9390 8742</Link><br />
                <Link to='mailto:info@simplyortho.com.au'>info@simplyortho.com.au</Link></p>
              </div>
            </div>
            <div className='footer__col'>
              <h4 className={colActive[1] ? 'active' : ''} onClick={() => this._toggleCol(1)}>Horsham</h4>
              <div className={colActive[1] ? 'footer__col-wrap active' : 'footer__col-wrap'}>
                <p>6 Robinson Street<br />
                Horsham VIC 3400</p>
                <p><Link to='tel:+61393908742'>(03) 9390 8742</Link><br />
                <Link to='mailto:info@simplyortho.com.au'>info@simplyortho.com.au</Link></p>
              </div>
            </div>
            <div className='footer__col'>
              <p>© {new Date().getFullYear()} Simply Orthodontics</p>
            </div>
          </div>
        </footer>
        <Link className='mobile-cta' to='tel:+61393908742'>
          <img src={Phone} alt='Call Now' />
          <span>Call Now</span>
        </Link>
      </>
    )
  }
}

export default Footer
