import React, { Component } from 'react'
import Link from '../utils/link'

import Logo from '../assets/images/logo.svg'
import LogoWhite from '../assets/images/logo-white.svg'
import Close from '../assets/images/close.svg'
import Back from '../assets/images/back.svg'
import { Instagram, Facebook } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    scrolled: false,
    locations: false,
    services: false,
    announcement: true
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentDidUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    this.setState({
      scrolled: window.scrollY > 1,
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _closeAnnouncement = () => {
    console.log('Clicked')
    this.setState({ announcement: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _toggleServices = () => {
    this.setState({ services: !this.state.services })
  }

  _toggleLocations = () => {
    this.setState({ locations: !this.state.locations })
  }

  render() {

    let { offCanvas, scrolled, services, locations, announcement } = this.state
    let { serviceLinks, wp } = this.props

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

    let headerClass = 'header'
    if (scrolled) headerClass += ' header--scrolled'
    if (announcement && wp.siteSettings.acf.announcement.text) headerClass += ' header--announcement'

    return (
      <>
        { wp.siteSettings.acf.announcement.text && announcement &&
          <section className='announcement'>
            <div className='announcement__inner'>
              <p dangerouslySetInnerHTML={{ __html: wp.siteSettings.acf.announcement.text }} />
              <button className='announcement__close' onClick={() => this._closeAnnouncement()}>
                <img src={Close} alt={`Close`} />
              </button>
            </div>
          </section>
        }
        <header className={headerClass}>
          <div className='header__inner'>
            <Link className='header__logo' to='/' title='Simply Ortho' {...props}>
              <img className='header__logo--white' src={LogoWhite} alt='Simply Ortho' />
              <img className='header__logo--green' src={Logo} alt='Simply Ortho' />
            </Link>
            <nav className='header__nav'>
              <ul>
                <li>
                  <Link to='https://www.instagram.com/simplyorthodontics.australia/'>
                    <Instagram color={'#FFFFFF'} />
                  </Link>
                </li>
                <li>
                  <Link to='https://www.facebook.com/simplyorthodontics/'>
                    <Facebook color={'#FFFFFF'} />
                  </Link>
                </li>
                <li>
                  <Link className='btn btn--green' to='tel:+61393908742'>
                    (03) 9390 8742
                  </Link>
                </li>
              </ul>
            </nav>
            <button onClick={this._toggleOffCanvas} className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={ offCanvas ? 'off-canvas off-canvas--active' : 'off-canvas' }>
          <div className='off-canvas__base'>
            <nav className='off-canvas__nav'>
              <ul>
                <li>
                  <button onClick={() => this._toggleServices()}>
                    <span>Services</span>
                    <span>+</span>
                  </button>
                </li>
                <li><Link to='/about' {...props}>About</Link></li>
                <li><Link to='/knowledge' {...props}>Knowledge</Link></li>
                <li>
                  <Link className='button' to='/locations' onClick={() => this._toggleLocations()}>
                    <span>Locations</span>
                    <span>+</span>
                  </Link>
                </li>
              </ul>
            </nav>
            <div className='off-canvas__social'>
              <ul>
                <li><Link to='/location/sydenham' {...props}>Sydenham</Link></li>
                <li><Link to='/location/sunbury' {...props}>Sunbury</Link></li>
                <li><Link to='/location/horsham' {...props}>Horsham</Link></li>
              </ul>
              <ul>
                <li><Link to='https://my.patientrewardshub.com/login'>Brace Bucks</Link></li>
              </ul>
            </div>
          </div>
          <div className={ services ? 'off-canvas__sub off-canvas__sub--services active' : 'off-canvas__sub off-canvas__sub--services' }>
            <nav className='off-canvas__nav'>
              <ul>
                <li>
                  <button onClick={() => this._toggleServices()}>
                    <span>Services</span>
                    <span>—</span>
                  </button>
                  { serviceLinks &&
                    <ul>
                      { serviceLinks.edges.map((el, i) => (
                        <li key={i}><Link to={`/service/${el.node.slug}`} {...props}>{el.node.title}</Link></li>
                      )) }
                      <li><Link to='https://my.patientrewardshub.com/login'>Brace Bucks</Link></li>
                    </ul>
                  }
                </li>
              </ul>
            </nav>
            <div className='off-canvas__social'>
              <button className='off-canvas__back' onClick={() => this._toggleServices()}>
                <img src={Back} alt='Back to main menu' />
                <span>Main Menu</span>
              </button>
            </div>
          </div>
          <div className={ locations ? 'off-canvas__sub off-canvas__sub--locations active' : 'off-canvas__sub off-canvas__sub--locations' }>
            <nav className='off-canvas__nav'>
              <ul>
                <li>
                  <button onClick={() => this._toggleLocations()}>
                    <span>Locations</span>
                    <span>—</span>
                  </button>
                  <ul>
                    <li><Link to='/location/sydenham' {...props}>Sydenham</Link></li>
                    <li><Link to='/location/sunbury' {...props}>Sunbury</Link></li>
                    <li><Link to='/location/horsham' {...props}>Horsham</Link></li>
                    <li><Link to='/contact-us' {...props}>Contact</Link></li>
                  </ul>
                </li>
              </ul>
            </nav>
            <div className='off-canvas__social'>
              <button className='off-canvas__back' onClick={() => this._toggleLocations()}>
                <img src={Back} alt='Back to main menu' />
                <span>Main Menu</span>
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
